import { NumberInput } from "./input";
import { clsx } from "../lib/functions";
import { jsx, jsxs } from "react/jsx-runtime";
const Range = ({
  label,
  min,
  max,
  value,
  step,
  onChange,
  hintText,
  appendix,
  errorText,
  className,
  inputSize = "medium",
  fieldEditable = true
}) => {
  let widthPercent = (value - min) / (max - min) * 100;
  if (widthPercent < 0) {
    widthPercent = 0;
  } else if (widthPercent > 100) {
    widthPercent = 100;
  }
  return /* @__PURE__ */ jsxs("div", {
    className: clsx(`mec-range`, className),
    children: [
      /* @__PURE__ */ jsx(NumberInput, {
        value,
        appendix,
        label,
        hintText,
        disabled: !fieldEditable,
        onChange,
        size: inputSize,
        errorText
      }),
      /* @__PURE__ */ jsxs("label", {
        className: "mec-range__slider",
        children: [
          /* @__PURE__ */ jsx("input", {
            type: "range",
            min,
            max,
            value,
            step,
            onChange: (e) => onChange(parseInt(e.target.value))
          }),
          /* @__PURE__ */ jsx("div", {
            className: "mec-range__slider__fill",
            style: {
              zIndex: 50,
              width: `${widthPercent}%`
            }
          })
        ]
      })
    ]
  });
};
export {
  Range
};
